import type { v1, ExtractFromAPI } from "@netgame/openapi";

export type InitDataResponse = ExtractFromAPI<v1.paths, "/rest/app/init/", "get">;
export type InitDataResponseData = InitDataResponse["data"];

export type PromoRegisterResponse = ExtractFromAPI<v1.paths, "/rest/player/promo/register/", "get">;
export type PromoRegister = NonNullable<PromoRegisterResponse["data"]>;

export type InitDataBanner = NonNullable<InitDataResponseData>["bannerPresetPackage"];

export type InitDataResponseNonNullable = NonNullable<InitDataResponse["data"]>;
export type InitPresetPackage = NonNullable<InitDataResponseNonNullable["popupPresetPackage"]>;
export type InitPresetPackages = NonNullable<InitDataResponseNonNullable["popupPresetPackages"]>;

export type AllGamesResponse = ExtractFromAPI<v1.paths, "/rest/page/issues/all-games/", "get">;
export type GamesPayload = NonNullable<AllGamesResponse["payload"]>;
export type Games = GamesPayload["games"];
export type GamesMenu = GamesPayload["menu"];
export type Game = NonNullable<Games>[number];

export type RegisterV2Response = ExtractFromAPI<v1.paths, "/rest/registerV2/", "post">;
export type ReferralInfoResponse = ExtractFromAPI<v1.paths, "/rest/page/referral-info/", "get">;

export type PromotionsResponse = ExtractFromAPI<v1.paths, "/rest/page/promotions/", "get">;
export type PromotionsPayload = NonNullable<PromotionsResponse["payload"]>;
export type Promotions = PromotionsPayload["promotions"];
export type Promo = NonNullable<Promotions>[number];
export type PromoData = NonNullable<Promo["data"]>;
export type Promotion = {
	available?: boolean;
	skeleton?: boolean;
	type?:
		| "invite"
		| "tournament"
		| "verification"
		| "dailyWheel"
		| "inviteQualification"
		| "emailLottery"
		| "offerDeposit"
		| "offerBirthday"
		| "promoOfferConstructor"
		| "offerPeriodic"
		| "partnerReferralQualification"
		| "rushHourOffers"
		| "quest"
		| "offerDate"
		| "magicBox"
		| "piggyBank"
		| "seasons"
		| "flipTheCoin"
		| "offerPersonal"
		| "season"
		| "specialMagicBox"
		| "scratchCards"
		| "challenge"
		| "depositStreak";
	data?: PromoData;
};

export type HomeJackpot = NonNullable<HomePayload["jackpots"]>;
export type JackpotObj = NonNullable<HomeJackpot["TRN" | "USD"]>;
export type JackpotValue = NonNullable<JackpotObj["values"]>;
export type HomeGames = NonNullable<HomePayload["games"]>;
export type HomeIssues = NonNullable<HomeGames["issues"]>;
export type HomePopularGames = NonNullable<HomeIssues["popular-games"]>;
export type HomeHoldNLinksGames = NonNullable<HomeIssues["hold-n-link"]>;
export type HomePromotions = NonNullable<HomePayload["promotions"]>;

export type PlayersActivityResponse = ExtractFromAPI<v1.paths, "/rest/players-activity/", "get">;
export type PlayersActivityArray = NonNullable<PlayersActivityResponse["data"]>;
export type PlayersActivity = PlayersActivityArray[number];
export type PlayersActivityGame = PlayersActivity["data"];

export type AllJackpotsResponse = ExtractFromAPI<v1.paths, "/rest/jackpots/history/", "get">;

export type AllGamesFiltersResponse = ExtractFromAPI<v1.paths, "/rest/get-game-filters/", "get">;
export type AllGamesFiltersData = NonNullable<AllGamesFiltersResponse["data"]>;
export type AllGamesFilters = NonNullable<AllGamesFiltersData["games"]>;
export type GameFilters = NonNullable<AllGamesFilters[number]>;

export type AvailableSoonItems = PromotionsPayload["available_soon"];
export type AvailableSoon = NonNullable<AvailableSoonItems>[number];
export type TaskType = {
	isCompleted: boolean;
};

export type ProfilePageResponse = Required<ExtractFromAPI<v1.paths, "/rest/page/profile/", "get">>;
export type ProfileFileResponse = ExtractFromAPI<v1.paths, "/rest/player/documents/", "get">;
export type ProfileFile = {
	status?: string;
	name?: string;
	size?: number;
	sizeError?: boolean;
	data?: File;
} & ProfileFileResponse[number];

export type AllHomeResponse = ExtractFromAPI<v1.paths, "/rest/page/home/", "get">;
export type HomePayload = NonNullable<AllHomeResponse["payload"]>;
export type HomeBanners = NonNullable<HomePayload["banners"]>;
export type HomeBanner = HomeBanners[number];

export type MoneyBoxInfoResponse = ExtractFromAPI<v1.paths, "/rest/money-box/info/", "get">;
export type MoneyBoxInfoGames = MoneyBoxInfoResponse["games"];
export type MoneyBoxInfoData = MoneyBoxInfoGames & MoneyBoxInfoResponse["amount"] & MoneyBoxInfoResponse["status"];

export type MoneyBoxInfo = {
	games: MoneyBoxInfoGames;
	amount: MoneyBoxInfoResponse["amount"];
	status: MoneyBoxInfoResponse["status"];
};

export type GameFiltersResponse = ExtractFromAPI<v1.paths, "/rest/get-game-filters/", "get">;
export type GameFiltersData = NonNullable<GameFiltersResponse["data"]>;
export type GameFiltersGames = GameFiltersData["games"];

export type Social = {
	id: string;
	name: "google" | "facebook";
	href: string;
	icon: string;
};

export type UserData = {
	login: string;
	password: string;
};

export type RegisterData = {
	contact: string;
	password: string;
	agree: boolean;
	timezone: string;
};

export type TournamentResponse = ExtractFromAPI<v1.paths, "/rest/tournament/", "get">;
export type TournamentsData = NonNullable<TournamentResponse["data"]>;
export type TournamentPlayerData = NonNullable<TournamentResponse["playerData"]>;
export type TournamentWinners = NonNullable<TournamentsData["winners"]>;
export type TournamentPrizes = NonNullable<TournamentsData["prizes"]>;
export type TournamentWinner = NonNullable<TournamentWinners[number]>;

export type SocketResponseTournament = {
	type: string;
	action: string;
	bets_count: number;
	winners: TournamentWinner[];
	historyId: string;
	next: Array<Record<string, string>>;
	prev: Array<Record<string, string>>;
	playerData: TournamentPlayerData;
	data: {
		totalPoints: number;
		place: number;
	};
};

export type EventData<TData> = {
	type: string;
	action: string;
	data: TData;
};

export type BalanceUpdate = {
	balance: string;
	bonus: Array<Record<string, unknown>>;
	bonusV2: string;
	cashUrl: string;
	cashbackAmount: number;
	compPoints: string;
	compPointsTotal: string;
	currency: string;
	entries: number;
	event: string;
	freespins_remain: number;
	leftRoleplay: number;
	roleplayLeftInPercent: number;
	roleplaying: string;
	tourPoints: number;
	winnings: string;
};

export type NotificationMessage = NonNullable<
	NonNullable<InitDataResponseData>["notificationCenter"]
>["messages"][number];

export type NotificationMessagePayload = {
	id: number;
	needSendStatusToServer: boolean;
	shouldOpenLink: boolean;
};

export type GameModes = "TournamentPoints" | "SweepStakes";

export type SocketQuestData = {
	dayIndex: number;
	taskIndex: number;
	questActiveId: number;
	name: string;
	logo: string;
	title: string;
	dateStart: string;
	dateEnd: string;
	playerData: {
		place?: number;
		completedTasks?: number;
		totalTasks?: number;
		collectedPoints?: number;
		collectedEntries?: number;
		collectedCoins?: number;
		prize?: {
			entries?: number;
			coins?: number;
		};
	};
} & Pick<QuestDayTasks[number], "executionTimeData" | "repeat" | "progress" | "prize">;

export enum EInsufficientFunds {
	TOURNAMENT_WITH_OFFER,
	SWEEPSTAKES_WITH_OFFER,
	NO_OFFER,
	SWEEPSTAKES_WITH_WINNINGS
}

export interface ConversionItem {
	key: string;
	label?: string;
	value: number;
}
export type QuestsResponse = ExtractFromAPI<v1.paths, "/rest/page/quests/", "get">;
export type QuestsWinnersResponse = ExtractFromAPI<v1.paths, "/rest/quest/get-winners/", "post">;
export type QuestPlayerData = NonNullable<QuestsResponse["payload"]>["playerData"];
export type QuestDays = NonNullable<QuestPlayerData>["days"];
export type QuestDayTasks = QuestDays[number]["tasks"];

export type QuestInfoResponse = ExtractFromAPI<v1.paths, "/rest/quest/", "get">;
export type QuestInfoData = NonNullable<QuestInfoResponse["data"]>;

export type BalanceResponse = ExtractFromAPI<v1.paths, "/rest/player/balance/", "get">;

export type DepositComplete = {
	action: string;
	amount: string | null;
	bannerPresetPackage: InitPresetPackage | null;
	betsAmount: string | number | null;
	bonusBanner: string | number | null;
	bonusEntries: string | number | null;
	coins: number | null;
	entries: number | null;
	isFirstDeposit: boolean;
	needBetsAmount: number;
	needIdentityVerification: boolean;
	playerData: {
		depositsCount?: number;
		needConfirmPhone: boolean;
	};
	playerDataHash: string | null;
	priorityBonus: string | null;
	showInsuranceInfo: boolean;
	type: string;
	popupPresetPackage: InitPresetPackage;
	popupPresetPackages: InitPresetPackages;
};

export type DetailInvite = {
	type: string;
	action: string;
	coins: number;
	entries: number;
};

export type SocketSeasonData = {
	completedLevelIndex?: number;
	levelProgressPercents?: number;
	entries?: number;
	coins?: number;
	currentLevel?: number;
};

export type Column = {
	headerName: string;
	field: string;
	class?: string;
	width: number;
};

export type Pagination = {
	current: number | null;
	pages: number | null;
};

export type Row = {
	[key: string]: string | number;
};

export type NextTournamentsResponse = ExtractFromAPI<v1.paths, "/rest/next-tournaments/", "get">;
export type NextTournaments = NonNullable<NextTournamentsResponse["tournaments"]>;
export type NextTournament = NonNullable<NextTournaments[number]>;
export type NextTournamentWinners = NonNullable<NextTournament["winners"]>;

export type PrevTestTournamentsResponse = ExtractFromAPI<v1.paths, "/rest/tournaments/history/", "get">;
export type PrevTestTournaments = NonNullable<PrevTestTournamentsResponse["tournaments"]>;
export type PrevTestTournament = NonNullable<PrevTestTournaments[number]>;
export type PrevTestTournamentWinners = NonNullable<PrevTestTournament["winners"]>;

export type PrevTournamentsResponse = ExtractFromAPI<v1.paths, "/rest/tournaments/history/", "get">;
export type PrevTournaments = NonNullable<PrevTournamentsResponse["tournaments"]>;
export type PrevTournament = NonNullable<PrevTournaments[number]>;
export type PrevTournamentWinners = NonNullable<PrevTournament["winners"]>;

export type ExtendedTournament = NextTournament | PrevTournament;

export type ExtendTournamentProps = NextTournament & PrevTournament;

export type ExtendWinners = TournamentWinners & PrevTournamentWinners & NextTournamentWinners;

export type TaskDetails = {
	games?: number[];
	gameId?: string;
};

export type TaskAction = {
	type: "gamesList" | "game";
	details?: TaskDetails;
};

export type CashResponse = ExtractFromAPI<v1.paths, "/rest/cash/", "get">;
export type CashData = NonNullable<CashResponse["data"]>;
export type ArrayWithToReversed<T> = Array<T> & {
	toReversed(): ArrayWithToReversed<T>;
};

export type ScratchCardsInfoData = ExtractFromAPI<v1.paths, "/rest/scratch-card-lottery/get/", "get">;
export type ScratchCardsTicketPlayData = ExtractFromAPI<v1.paths, "/rest/scratch-card/prize/resolve/", "post">;
export type ScratchCardsTicketData = NonNullable<ScratchCardsTicketPlayData["data"]>;
export type ScratchCardsTicketItems = NonNullable<ScratchCardsTicketData["items"]>;
export type ScratchCardsTicketItem = NonNullable<ScratchCardsTicketItems[number]>;
export type ScratchCardsTypes = "bronze" | "silver" | "gold";

export type DepositStreakInfoData = ExtractFromAPI<v1.paths, "/rest/deposit-streak/info/", "get">;
export type DepositStreakInfo = DepositStreakInfoData & { progress: boolean[] } & { canWheel?: boolean };
